@tailwind base;
@tailwind components;
@tailwind utilities;
#txtPassword {
  -webkit-text-security: disc;
}
.ql-editor{
  min-height:200px;
}
/* In your global styles or CSS file */
::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color:#374151; /* Set the color of the thumb */
    border-radius: 6px; /* Set the border radius of the thumb */
  }
  
  ::-webkit-scrollbar-track {
    background-color: #ddd; /* Set the color of the track */
  }
  
  /* For Firefox */
  /* Note: Firefox currently supports only a limited set of scrollbar customizations */
  /* These styles might not work in all versions of Firefox */
  
  /* Set the width of the scrollbar */
  /* This is not supported in Firefox Nightly and may be subject to change */
  * {
    scrollbar-width: thin;
    scrollbar-color: #4f4f4f #ddd;
  }
  